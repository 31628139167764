<template>
  <div>
    <p class="collection">
      <el-button :type="article=='1'?'primary':''" @click="tapBtn('1')">智能问答</el-button>
      <el-button :type="article=='2'?'primary':''" @click="tapBtn('2')">知识头条</el-button>
      <el-button :type="article=='3'?'primary':''" @click="tapBtn('3')">资源交易</el-button>
    </p>


    <ul class="xl" v-if="article=='1'">
      <!-- 智能问答 -->
      <li class="plcont" v-for="(item,i) in Collectionlist" :key="i">
        <ul>
          <li class="name" v-if="item.answer.length < 30">
            {{ item.answer }}<span class="type">{{ item.type }}</span>
          </li>
          <li class="name" v-if="item.answer.length > 30">
            {{ item.answer.substring(0, 30) + "..."
            }}<span class="type">{{ item.type }}</span>
          </li>
          <li class="te">{{ item.comment }}</li>
          <li class="user">
            <span class="time">{{ item.time }}</span>
            <span class="dele">
              <el-button
                type="text"
                @click="deleteConcerns(item.id, item.whole)"
                >删除</el-button
              ></span
            >
            <span class="dele">
              <el-button type="text" @click="cancelConcerns(item.whole)"
                >查看详情</el-button
              ></span
            >
          </li>
        </ul>
      </li>
    </ul>
    <ul class="xl" v-if="article=='2'">
      <!-- 知识头条 -->
      <li class="plcont" v-for="(item,i) in Collectionlists" :key="i">
        <ul>
          <li class="name">
            <div class="panswer">{{ item.answer }}</div>
            <span class="type">{{ item.type }}</span>
          </li>

          <li class="te">{{ item.comment }}</li>
          <li class="user">
            <span class="time">{{ item.time }}</span>
            <span class="dele">
              <el-button type="text" @click="WZPL(item.id, item.whole)"
                >删除</el-button
              ></span
            >
            <span class="dele">
              <el-button type="text" @click="cancelConcerne(item.whole)"
                >查看详情</el-button
              ></span
            >
          </li>
        </ul>
      </li>
    </ul>
    <ul class="xl" v-if="article=='3'">
      <!-- 资源交易 -->
      <li class="plcont" v-for="(item,i) in Collectionlisted" :key="i">
        <ul>
          <li class="name" v-if="item.answer.length < 30">
            {{ item.answer }}<span class="type">{{ item.type }}</span>
          </li>
          <li class="name" v-if="item.answer.length > 30">
            {{ item.answer.substring(0, 30) + "..."
            }}<span class="type">{{ item.type }}</span>
          </li>

          <li class="te">{{ item.comment }}</li>
          <li class="user">
            <span class="time">{{ item.time }}</span>
            <span class="dele">
              <el-button type="text" @click="deleteConcern(item.id)"
                >删除</el-button
              ></span
            >
            <span class="dele">
              <el-button type="text" @click="cancelConcernd(item.whole)"
                >查看详情</el-button
              ></span
            >
          </li>
        </ul>
      </li>
    </ul>
    <div class="pageNum">
      暂无数据
    </div>
    <div class="pageNum">
      <el-pagination
      background
      :page-size="limit"
      @current-change="changePage"
      layout="prev, pager, next"
      :total="count">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { INSERT, QUERY, DELETE, UPDATE, QUERYED } from "@/services/dao.js";
import { showTimeFormat } from "@/js/common";
export default {
  data() {
    return {
      Collectionlist: [],
      Collectionlists: [],
      Collectionlisted: [],
      content: "",
      time: "",
      source: "",


      article:1,
      limit:10,  //每页数据
      page:1,  //当前页
      count:0   //数据总数
    };
  },
  created() {
    this.changePage(1);
  },
  methods: {
    tapBtn(num){
      this.article = num
      this.changePage(1)
    },

    changePage(e){
      this.page = e
      this.Collectionlist = []
      this.Collectionlists = []
      this.Collectionlisted = []
      switch (this.article) {
        case '1':this.znwd()
          break;
        case '2':this.zstt()
          break;
        case '3':this.zyjy()
          break;
      }
    },

    // 智能问答
    async znwd(){
      let id = this.$store.state.userinfo.id
      let sql = `WDSeeComment (where: {commentator: {_eq:${id}}}) {id answer  time  content  whole  }`
      sql +=` WDSeeComment_aggregate(where:{commentator: {_eq:${id}}}) {aggregate {count}}`
      let res = await QUERYED(
        "post","",sql
      );
      this.count = res.data.WDSeeComment_aggregate.aggregate.count
      for (let i = 0; i < res.data.WDSeeComment.length; i++) {
        this.Collectionlist.push({
          id: res.data.WDSeeComment[i].id,
          time: showTimeFormat(res.data.WDSeeComment[i].time),
          answer: res.data.WDSeeComment[i].answer,
          comment: res.data.WDSeeComment[i].content,
          whole: res.data.WDSeeComment[i].whole,
          type: "智能问答",
        });
      }
    },

    //知识头条
    async zstt(){
      let id = this.$store.state.userinfo.id
      let sql = `TTSeeComment (where: {commentator: {_eq:${id}}}) {id  articleTitle time  content  whole  }`
      sql +=` TTSeeComment_aggregate(where:{commentator: {_eq:${id}}}) {aggregate {count}}`
      let data = await QUERYED(
        "post","",sql
      );
      this.count = data.data.TTSeeComment_aggregate.aggregate.count
      for (let i = 0; i < data.data.TTSeeComment.length; i++) {
        this.Collectionlists.push({
          id: data.data.TTSeeComment[i].id,
          time: showTimeFormat(data.data.TTSeeComment[i].time),
          answer: data.data.TTSeeComment[i].articleTitle,
          comment: data.data.TTSeeComment[i].content,
          whole: data.data.TTSeeComment[i].whole,
          type: "知识头条",
        });
      }
    },

    //资源交易
    async zyjy(){
      let id = this.$store.state.userinfo.id
      let sql = `ZYSeeComment (where: {commentator: {_eq:${id}}}) {id  articlename time   content  whole  }`
      sql +=` ZYSeeComment_aggregate(where:{commentator: {_eq:${id}}}) {aggregate {count}}`
       let datas = await QUERYED(
        "post","",sql
      );
      this.count = datas.data.ZYSeeComment_aggregate.aggregate.count
      for (let i = 0; i < datas.data.ZYSeeComment.length; i++) {
        this.Collectionlisted.push({
          id: datas.data.ZYSeeComment[i].id,
          time: showTimeFormat(datas.data.ZYSeeComment[i].time),
          answer: datas.data.ZYSeeComment[i].articlename,
          comment: datas.data.ZYSeeComment[i].content,
          whole: datas.data.ZYSeeComment[i].whole,
          type: "资源交易",
        });
      }
    },


    ///显示
    // async init() {
      // this.Collectionlist = [];
      // let res = await QUERYED(
      //   "post",
      //   "",
      //   "  WDSeeComment (where: {commentator: {_eq: " +
      //     this.$store.state.userinfo.id +
      //     "}}) {id answer  time  content  whole  }"
      // );
      // for (let i = 0; i < res.data.WDSeeComment.length; i++) {
      //   this.Collectionlist.push({
      //     id: res.data.WDSeeComment[i].id,
      //     time: showTimeFormat(res.data.WDSeeComment[i].time),
      //     answer: res.data.WDSeeComment[i].answer,
      //     comment: res.data.WDSeeComment[i].content,
      //     whole: res.data.WDSeeComment[i].whole,
      //     type: "智能问答",
      //   });
      // }
      // this.Collectionlists = [];
      // let data = await QUERYED(
      //   "post",
      //   "",
      //   "  TTSeeComment (where: {commentator: {_eq: " +
      //     this.$store.state.userinfo.id +
      //     "}}) {id  articleTitle time  content  whole  }"
      // );
      // for (let i = 0; i < data.data.TTSeeComment.length; i++) {
      //   this.Collectionlists.push({
      //     id: data.data.TTSeeComment[i].id,
      //     time: showTimeFormat(data.data.TTSeeComment[i].time),
      //     answer: data.data.TTSeeComment[i].articleTitle,
      //     comment: data.data.TTSeeComment[i].content,
      //     whole: data.data.TTSeeComment[i].whole,
      //     type: "知识头条",
      //   });
      // }

      // this.Collectionlisted = [];
      // let datas = await QUERYED(
      //   "post",
      //   "",
      //   "  ZYSeeComment (where: {commentator: {_eq: " +
      //     this.$store.state.userinfo.id +
      //     "}}) {id  articlename time   content  whole  }"
      // );
      // for (let i = 0; i < datas.data.ZYSeeComment.length; i++) {
      //   this.Collectionlisted.push({
      //     id: datas.data.ZYSeeComment[i].id,
      //     time: showTimeFormat(datas.data.ZYSeeComment[i].time),
      //     answer: datas.data.ZYSeeComment[i].articlename,
      //     comment: datas.data.ZYSeeComment[i].content,
      //     whole: datas.data.ZYSeeComment[i].whole,
      //     type: "资源交易",
      //   });
      // }
    // },

    // 资源交易
    async deleteConcern(id) {
      console.log(id);
      let obj = await DELETE(
        "POST",
        "",
        "   delete_Comment(where: {id: {_eq: " + id + "}}) {   affected_rows  }"
      );
      if (obj.data.delete_Comment.affected_rows >= 1) {
        this.$message({
          message: "删除成功！",
          type: "success",
        });
        this.changePage(this.page)
      } else {
        this.$message({
          message: "删除失败！",
          type: "warning",
        });
      }
    },
    //知识头条、
    async WZPL(id, whole) {
      let obj = await DELETE(
        "POST",
        "",
        "delete_Comment(where: {id: {_eq: " + id + "}}) {   affected_rows  }"
      );

      if (obj.data.delete_Comment.affected_rows >= 1) {
         //查询评论数
        let data = await QUERYED(
          "post",
          "",
          "  Article(where: {id: {_eq: " + whole + "}}) {   commentNum      }"
        );
        //评论数+1
        data.data.Article[0].commentNum += 1;
        //修改文章表中的评论数
        let datdeds = await UPDATE(
          "post",
          "",
          "  update_TtArticle(where: {id: {_eq: " +
            whole +
            "}}, _set: {commentNum: " +
            data.data.Article[0].commentNum +
            "}) { affected_rows }"
        );
        if (datdeds.data.update_TtArticle.affected_rows > 0) {
          this.$message({
            message: "删除成功！",
            type: "success",
          });
          this.changePage(this.page)
        }
      } else {
        this.$message({
          message: "删除失败！",
          type: "warning",
        });
      }
    },
    // 智能问答
    async deleteConcerns(id, whole) {
      let obj = await DELETE(
        "POST",
        "",
        "delete_Comment(where: {id: {_eq: " + id + "}}) {   affected_rows  }"
      );
      if (obj.data.delete_Comment.affected_rows >= 1) {
        //获取评论数
        let data = null;
        data = await QUERYED(
          "post",
          "",
          "  WDUserQuestionAnswerRef(where: {wdanswerref: {_eq: " +
            whole +
            "}}) { answers }"
        );
        data.data.WDUserQuestionAnswerRef[0].answers -= 1;
        //修改评论数
        let dataed = await UPDATE(
          "post",
          "",
          "   update_WdAnswerRef(where: {id: {_eq: " +
            whole +
            "}}, _set: {answers: " +
            data.data.WDUserQuestionAnswerRef[0].answers +
            "}) {  affected_rows }"
        );
        if (dataed.data.update_WdAnswerRef.affected_rows > 0) {
          this.$message({
            message: "删除成功！",
            type: "success",
          });
          this.changePage(this.page)
        }
      } else {
        this.$message({
          message: "删除失败！",
          type: "warning",
        });
      }
    },
    //智能问答（查看详情）
    async cancelConcerns(whole) {
      console.log("回答ID" + whole);
      // 通过回答ID查找问题ID
      let info = await QUERY(
        "POST",
        "",
        "  WDAnswerRef(where: {id: {_eq: " + whole + "}}) {  question }"
      );
      this.$router.push({
        path: "/writeanswer",
        query: {
          id: info.data.WDAnswerRef[0].question,
        },
      });
    },
    // 知识头条查看详情
    async cancelConcerne(whole) {
      console.log(whole);
      this.$router.push({
        path: "/articleDetail",
        query: {
          id: whole,
        },
      });
    },
    // 知识头条查看详情
    async cancelConcernd(whole) {
      console.log(whole);
      this.$router.push({
        path: "/Resource_details",
        query: {
          id: whole,
        },
      });
    },
  },
};
</script>

<style scoped>
.collection {
  width: 100%;
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  padding: 5px 21px 21px 21px;
  border-bottom: solid 1px #e8e8e8;
}
.el-button {
  margin-left: 105px;
}
.active {
  background: #0084ff;
  color: #ffffff;
}

ul {
  padding: 0px;
}
.plcont {
  padding: 20px 20px 19px 30px;
  border-bottom: solid 1px #e8e8e8;
  /* border: 1px solid red; */
  width: 100%;
}
.name {
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  margin-bottom: 10px;
  overflow: hidden;
}
.dele {
  margin-left: 50px;
}
.num {
  float: right;
}
.type {
  float: right;
}
.panswer {
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  float: left;
}
.pageNum{
  width: 100%;
  padding-top: 20px;
  display: flex;
  justify-content: center;
}
</style>
